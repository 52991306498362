import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги | Наші юридичні послуги в 24i Vision
			</title>
			<meta name={"description"} content={"поза юридичними рішеннями – розробка чіткості відповідно до ваших унікальних потреб\n"} />
			<meta property={"og:title"} content={"Наші послуги | Наші юридичні послуги в 24i Vision"} />
			<meta property={"og:description"} content={"поза юридичними рішеннями – розробка чіткості відповідно до ваших унікальних потреб\n"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline1"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Наші послуги
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
				color="#7c7f81"
				padding="0px 20px 0px 20px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Розпочніть детальне дослідження правових рішень у 24i Vision. Наші послуги долають традиційні межі, пропонуючи індивідуальну чіткість і чіткість відповідно до ваших унікальних потреб. Від особистих юридичних питань до бізнес-консультаційних послуг, перегляду контрактів, вирішення спорів і консультацій із трудового законодавства, кожна пропозиція розроблена з точністю. Приєднуйтесь до нас у навігації юридичними ландшафтами, зосереджуючись на ваших конкретних вимогах.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Особисті юридичні питання:
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						text-align="center"
						font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
						color="#7c7f81"
						padding="0px 20px 0px 20px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 50px 0px"
					>
						Спеціальні юридичні рішення для особистих проблем, забезпечення ясності та вказівок.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Бізнес-консультаційні послуги:
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						text-align="center"
						font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
						color="#7c7f81"
						padding="0px 20px 0px 20px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 50px 0px"
					>
						Стратегічний юридичний консультант для бізнесу, який долає складні питання з метою зростання.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Огляд та складання договору:
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						text-align="center"
						font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
						color="#7c7f81"
						padding="0px 20px 0px 20px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 50px 0px"
					>
						Комплексний аналіз і розробка контрактів, забезпечення дотримання законодавства та захисту.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Вирішення суперечок:
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						text-align="center"
						font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
						color="#7c7f81"
						padding="0px 20px 0px 20px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 50px 0px"
					>
						Експертна допомога у вирішенні судових суперечок, зосереджена на швидкому та ефективному вирішенні.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Text margin="0px 0px 15px 0px" font="normal 600 24px/1.2 --fontFamily-sans" text-align="center">
						Консультація з питань трудового права:
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						text-align="center"
						font="normal 300 22px/1.5 --fontFamily-sansHelvetica"
						color="#7c7f81"
						padding="0px 20px 0px 20px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 50px 0px"
					>
						Керівництво підприємствами та окремими особами з питань трудового законодавства, сприяння чесним і законним практикам.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0" sm-padding="40px 0 40px 0" background="url(https://uploads.quarkly.io/66f139da1b94b10024312051/images/1-6.jpg?v=2024-09-23T15:58:00.507Z) center/cover" min-height="700px">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="600px" padding="50px 50px 50px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Зв'язатися
				</Text>
				<Text font="--base" margin="16px 0px 25px 0px">
					Щоб отримати більш детальне розуміння наших послуг або обговорити ваші конкретні юридичні потреби, ми запрошуємо вас зв’язатися з 24i Vision. Наша спеціальна команда готова надати ясність і поради, які вам потрібні.
				</Text>
				<Button
					padding="14px 54px 14px 54px"
					background="#E65455"
					font="--lead"
					href="/contact-us"
					text-transform="uppercase"
					type="link"
					text-decoration-line="initial"
				>
					Консультація
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});
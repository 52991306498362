import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до 24i Vision
			</title>
			<meta name={"description"} content={"навігація в законних водах із чітким порадником – ваш союзник у ясності та рішучості"} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до 24i Vision"} />
			<meta property={"og:description"} content={"навігація в законних водах із чітким порадником – ваш союзник у ясності та рішучості"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66f139da1b94b10024312051/images/316633.png?v=2024-09-23T15:43:23.127Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section
			padding="80px 0 90px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="60%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
						24i Vision
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
						Зробіть крок у сферу, де юридичне супроводження – це не просто послуга, а партнерство. У 24i Vision ми тепло вітаємо окремих осіб, підприємства та організації, яким потрібна ясність у правовому середовищі. Наше зобов’язання просте – бути вашим союзником, надаючи чіткі поради, які допоможуть вам на шляху до вирішення проблеми. У складному світі ми виступаємо маяком простих, доступних і прозорих правових рішень. Ваші виклики унікальні, як і наші індивідуальні підходи. Ласкаво просимо до 24i Vision, де починається ваш шлях до ясності.
					</Text>
					<Button
						background="#E65455"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--lead"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#E65455"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contact-us"
						type="link"
						text-decoration-line="initial"
					>
						Звʼязатися з нами
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="40%"
					justify-content="flex-end"
					align-items="center"
					md-margin="0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://uploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15:58:00.481Z"
						display="block"
						margin="0px 0px 0px 0px"
						width="100%"
						md-margin="0px 0 0px 0px"
						srcSet="https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-1.jpg?v=2024-09-23T15%3A58%3A00.481Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section background="#f9f2f0" padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://uploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15:58:00.505Z"
					display="block"
					width="30%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
					object-position="0%"
					srcSet="https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-4.jpg?v=2024-09-23T15%3A58%3A00.505Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="70%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Чому варто обрати 24i Vision?
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						display="grid"
						grid-template-columns="repeat(3, 1fr)"
						margin="50px 0px 0px 0px"
						grid-gap="16px 24px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet">
									Прагматичний підхід
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								ми вирішуємо юридичні складнощі, пропонуючи чіткі та дієві поради, які допоможуть вам приймати обґрунтовані рішення.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet">
									Орієнтація на клієнта
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								ваші пріоритети є нашими пріоритетами. Ми слухаємо, розуміємо та налаштовуємо наші послуги відповідно до ваших унікальних обставин.
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							padding="25px 25px 25px 25px"
							background="#ffffff"
							box-shadow="--m"
						>
							<Box
								min-height={0}
								width="50px"
								height="50px"
								display="flex"
								align-items="center"
								justify-content="center"
								margin="0px 0px 15px 0px"
							>
								<Text margin="0px 0px 0px 0px" quarkly-title="1" font="normal 600 22px/1.2 --fontFamily-sansTrebuchet">
									Прозоре спілкування
								</Text>
							</Box>
							<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansTrebuchet">
								очікуйте відкритого та чесного спілкування на кожному кроці, гарантуючи, що ви будете добре поінформовані протягом судового процесу.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-15" background="#f9f2f0">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 35px 0px"
					sm-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15:58:00.489Z"
						display="block"
						object-fit="cover"
						margin="0px 5px 0px 0px"
						width="26%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
						srcSet="https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-5.jpg?v=2024-09-23T15%3A58%3A00.489Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15:58:00.485Z"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0 0 10px 0"
						srcSet="https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-3.jpg?v=2024-09-23T15%3A58%3A00.485Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15:58:00.488Z"
						display="block"
						object-fit="cover"
						margin="0px 0px 0px 5px"
						width="37%"
						sm-width="100%"
						sm-margin="0px 0px 0px 0"
						srcSet="https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66f139da1b94b10024312051/images/1-2.jpg?v=2024-09-23T15%3A58%3A00.488Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
					text-align="center"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#474e53" text-align="left">
						Щоб отримати більш детальне розуміння наших послуг або обговорити ваші конкретні юридичні потреби, ми запрошуємо вас зв’язатися з 24i Vision. Наша спеціальна команда готова надати ясність і поради, які вам потрібні.
					</Text>
					<Text
						margin="70px 0px 0px 0px"
						font="normal 300 28px/1.5 --fontFamily-sans"
						color="#474e53"
						text-align="center"
						width="100%"
					>
						Чіткі консультаційні рішення – розробка юридичних рішень спеціально для вас
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});